
import { Component, Vue } from 'vue-property-decorator';
import ConsolidatedByMarketChart from '@/components/ConsolidatedByMarketChart.vue';

@Component({
  components: {
    ConsolidatedByMarketChart,
  }
})
export default class ConsolidatedByMarketByProgram extends Vue {}
